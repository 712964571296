<template>
  <div class="backdrop">
    <div class="b-modal">
        <div class="modal-header text-center">
          <h4 class="text-uppercase text-white text-center">My CONTACT LIST</h4>
          <div>
            <p class="text-white p-3"  @click="closeModal()">X</p>
        </div>

        </div>
        <div class="modalBody" v-if="state == 'init'">
          <div >
            <span><button class="btn btn-main" @click="openAddContact()">
              Add Contact
            </button></span>
            <div class="market-content">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Email Address</th>
                    <th>Mobile Number</th>
                    <th>Action</th>
                   
                  </tr>
                </thead>
          
                <tbody>
                  <tr v-if="isLoading">
                    <td colspan="8" class="text-center">
                      <div class="spinner-container"><span class="spinner"> Loading ... </span></div>
                    </td>
                  </tr>
          
                 
                  <tr v-if="contactList && !isLoading" v-for="contact in contactList" :key="contact">
                    <td>
                      <div class="symbol-container">
                        <img class="small-icon" :src="require('../../assets/images/individ.png')" >
                        <div class="label-icon"> </div>
                      </div>
                    </td>
                    <td>{{contact.fullName}}</td>
                    <td>{{contact.emailAddress}} </td>
                    <td>{{contact.mobilePhone}}  </td>
                    <td><button class="btn btn-secondary" @click="removeContact(contact.entityId)">remove</button></td>
                
                
          
                  </tr>
          
                  <tr v-if="!isLoading && funds && funds.length == 0">
                    <td colspan="8" class="text-center">
                      No Data found
                    </td>
                  </tr>
                </tbody>
              </table>
              
            </div>
          </div>
        </div>
      </div>
      <AddContact v-if="showAddContact" @close="openAddContact"/>
     </div>
  </template>

  <script>
    import AddContact from '../../components/modal/AddContact.vue'

     export default {
          name: 'ContactListComponent',
          components:{
            AddContact
          },
          data(){
               return {
                state:'init',
                    entityId:'',
                    contactEntityId: '',
                    showAddContact: false,
                    contactList:[],
                    isLoading: false,
                    details:'',
                    error: {
                       msg: "",
                       
                   }
               }
           },
           computed: {
                show() {
                    return this.$store.state.show;
                },
                init() {
                    return this.$store.state.init;
                },
                
             
            },
          methods:{
            removeContact(entityId){
              this.contactEntityId = entityId
              const params={
                contactEntityId: this.contactEntityId
              }
              const options = {
                method: 'POST',
                headers: {
                          'content-type': 'application/x-www-form-urlencoded',
                          'blox-authz': localStorage.getItem("tokenId") 
                      },
                params, 
                url: '/cx/removecontact',
              }
              this.axios(options)
                .then((response) => {
                        console.log(response.data)
                        this.getContactList();
                      
                     
                }).catch((err) => {
                  
                })
            },
            getContactList(){
              const options = {
                      method: 'GET',
                      headers: {
                          'content-type': 'application/x-www-form-urlencoded',
                          'blox-authz': localStorage.getItem("tokenId") 
                      }, 
                    
                      url: '/cx/contacts',
                      }
                      this.axios(options)
                .then((response) => {
                        console.log(response.data)
                        this.contactList = response.data
                     
                }).catch((err) => {
                  
                })

            },
            openAddContact(){
              this.showAddContact = !this.showAddContact
            },
          
           
            getProfile() {
              const options = {
                      method: 'GET',
                      headers: {
                          'content-type': 'application/x-www-form-urlencoded',
                          'blox-authz': localStorage.getItem("tokenId") 
                      }, 
                    
                      url: '/cx/profile',
                      }
                      this.axios(options)
                .then((response) => {
                        this.show.profile = response.data;
                      
                      
                }).catch((err) => {
                })
            },
            sessionCheck() {
              const options = {
                      method: 'POST',
                      headers: {
                          'content-type': 'application/x-www-form-urlencoded',
                          'blox-authz': localStorage.getItem("tokenId") 
                      }, 
                    
                      url: '/cx/sessioncheck',
                      }
                this.axios(options)
                .then((response) => {
                this.show.hasSession = true;
                this.getProfile();
                }).catch((err) => {
                  this.show.hasSession = false;
                  
                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/" + this.init.currentLocation.toLowerCase() + "/login";
                        }  
                    if(err.response.data.msgCode == '00020') {

                      setTimeout( () => {
                      window.location.href = "/#/" + this.init.currentLocation.toLowerCase() + "/" + 'setup-profile';
                      },1000)

                      } 
                })
            },
            closeModal(){
              this.$emit("close");
                
              },
       
        
          clearError() {
            this.systemMsg = ""
            this.showError = false
          },

         
          isNumber: function(evt) {
              evt = (evt) ? evt : window.event;
              var charCode = (evt.which) ? evt.which : evt.keyCode;
              if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
                evt.preventDefault();
              } else {
                return true;
              }
            },

            getMobileCodes(){
                this.axios.get('/assets/js/mobilecodes.json')
                    .then((response) => {
                        this.list.mobileCodes = response.data;
                    }).catch((err) => {
                        console.log(err);

                    })
            },
 
            
            isAmount: function(evt) {
                    evt = (evt) ? evt : window.event;
                    var charCode = (evt.which) ? evt.which : evt.keyCode;
  
                        if ((charCode > 31 && charCode != 46  && (charCode < 48 || charCode > 57)) ) {
                        evt.preventDefault();
                        } else {
                         return true;
                        }
                },
               
      
            },
            mounted(){
             this.sessionCheck();
             
            },
            beforeMount(){
              this.getContactList();
            },
            watch: {
                   
                }
    }

  </script>


  <style scoped lang="scss">
.qr-wrapper {
     display:flex;
     align-items: center;
     justify-content:center;
   }
       .coin-container {
           display: flex;
           align-items: center;
          justify-content: space-around;
            
            .coin-list {
              width: 100px !important;
              height: 100px !important;
                .coin-details {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    height: 100px !important;
                    padding: 0.6em;
                    cursor: pointer;
                    border-radius: 6px;
                    &:hover {
                        background: #CCC;
                    }
 
                    img {
                        width: 70px;
                    }
                    label {
                        font-size: 10px;
                        padding: 10px 0px;
                    }
                }
                
            }
       }
  	


  .b-modal{
    width: 100%;
    height: auto;
    margin: 100px auto;
    background: white;
    border-radius: 10px;
    z-index: 2;
    max-height: calc(100vh - 110px);
    overflow-y: auto;
  }
.modal-header{
    width: 100%;
    background-image: linear-gradient(  to right,
          #202538 29%,
          #10121C 59%,
          ) !important;
  }
.modal-title{
    margin: 0 auto;
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
    
  }
  label{
    color: black;
  }
.btn-left{
  float: left;
  background-image: linear-gradient(to right, #CFA76B 0%, #695536 97%) !important;
  border: none !important;
  color: white;
  font-size: 15px;
}
.modal-title  svg {
    	fill: #FFF;
    	cursor: pointer;
    }
.modal-title img{
    width: 4rem;
  }
  
.backdrop{
  left: 0;
    top: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    z-index: 99;
  }
.b-modal h4{
    padding: 15px;
  }
  .btn{
    margin-right: 10px;
  }
  .market-content {

.symbol-container {
  display:flex;
  justify-content:center;
  align-items: end;
}
.small-icon {
  width: 25px;
}

.label-icon {
  margin-left: 0.5em
}

overflow-x:auto;
max-height: 40em;
width: 100%;
border-radius: 10px;
padding: 0;
table {
  width: 100%;
  thead {
    th {
      color: #FFFFFF; 
      letter-spacing: 0.65px;
      padding: 1em;
      position: sticky;
      top: 0;
       background-image: linear-gradient(
        to bottom,
        #202538 29%,
        #10121C 88%
  );
      text-align: inherit;
      font-family: 'SoinSansNeue-Bold';
    }
  }

  tbody {
     background-image: linear-gradient(
        to bottom,
        #202538 29%,
        #10121C 88%
  );
   
    td {
      color: #FFFFFF;
      letter-spacing: 0.65px;
      padding: 1em;

      a {
        color: #FFFFFF;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }  
      }

      &.hash {
        a {
          color: #FFBE21;
        }
        
      }
    }
  }
}
}
  </style>