<template>
  <router-view/>
</template>
<script>
 

  export default {
    name: 'App',
    computed: {
      init() {
          return this.$store.state.init;
      },
    },
    data: function() {
      return  {
        
      }
    },
    methods: {
      getLocation: function(ip) {
        const options = {
            method: 'GET',
            url: '/api-loc/json/' + ip,
            };
            this.axios(options)
                .then((response) => { 

                  this.init.currentLocation = response.data.countryCode == '-' ? "GB" : response.data.countryCode;
        
                  switch(response.data.countryCode) {
                    case "-":
                        window.location.href = "/#/gb";
                        break;
                    default:
                      window.location.href = "/#/" + response.data.countryCode.toLowerCase();
                      break;
                  }
                })
                .catch((error) => {
                  console.log(error)
                 
                });
      },
      getIp: function() {

         const options = {
            method: 'GET',
            url: '/json',
            };
            this.axios(options)
                .then((response) => { 

                  this.getLocation(response.data.ip)
                })
                .catch((error) => {
                  console.log(error)
                 
                });
        },
   },
 

    mounted(){


      setTimeout(() => { 
        if(this.$route.name == undefined) {
          this.getIp();
        }
        

      }, 300)
      
    },

    watch: {

    }
  }
</script>
 
<style lang="scss">

  /* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  @font-face {
    font-family: 'SoinSansNeue';
    src: url(./assets/font/SoinSansNeue-Roman.otf);
    font-style: normal;
  }

  @font-face {
    font-family: 'SoinSansNeue-Bold';
    src: url(./assets/font/SoinSansNeue-Bold.otf);
    font-style: normal;
  }

  
  html { 
    scroll-behavior: smooth;
    width: 100%;
    height: 100%;
  }

  body {
    font-family: 'SoinSansNeue';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;
    font-size: 0.9em;
  }

  .bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    pointer-events: none;
    object-fit: cover;
  }

  .input-group-prepend {
    margin-right: -1px;
  }

  .input-group-append, .input-group-prepend {
    display: flex;
  }

  .input-group.has-validation>.input-group-append:nth-last-child(n+3)>.btn, .input-group.has-validation>.input-group-append:nth-last-child(n+3)>.input-group-text, .input-group:not(.has-validation)>.input-group-append:not(:last-child)>.btn, .input-group:not(.has-validation)>.input-group-append:not(:last-child)>.input-group-text, .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle), .input-group>.input-group-append:last-child>.input-group-text:not(:last-child), .input-group>.input-group-prepend>.btn, .input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group-append .btn, .input-group-prepend .btn {
    position: relative;
    z-index: 2;
  }
  

  .input-group>.custom-file, .input-group>.custom-select, .input-group>.form-control, .input-group>.form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
  }

  .input-group {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
    border-radius: 6px;
    padding: 10px;
    .form-control {
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.5em 0.75rem;
      font-size: 0.9em;
      line-height: 1.5;
      color: #495057;
      background-color: #FFF;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 3px;
      outline: none;
    }
  }

  .input-group>.input-group-append>.btn, .input-group>.input-group-append>.input-group-text, .input-group>.input-group-prepend:first-child>.btn:not(:first-child), .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child), .input-group>.input-group-prepend:not(:first-child)>.btn, .input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group.has-validation>.custom-file:nth-last-child(n+3) .custom-file-label, .input-group.has-validation>.custom-file:nth-last-child(n+3) .custom-file-label:after, .input-group.has-validation>.custom-select:nth-last-child(n+3), .input-group.has-validation>.form-control:nth-last-child(n+3), .input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label, .input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label:after, .input-group:not(.has-validation)>.custom-select:not(:last-child), .input-group:not(.has-validation)>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  label{
    color: white;
  }
  .form-group{
    margin-top: 1em;
    .form-control {
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.5em 0.75rem;
      font-size: 0.9em;
      line-height: 1.5;
      color: #495057;
      background-color: #FFF;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 3px;
      outline: none;

      &.error {
        border: 2px solid #e74c3c;
      }
    }
  }

  button {
    &:disabled,
    &[disabled]{
      cursor: not-allowed !important;
    }
  }
  .text-center {
    text-align: center;
  }
  .btn {
    
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 3px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    cursor: pointer;
  }
  .btn-second{
    background-image: linear-gradient(to right, #866c2f 0%,
#6f621f 38%,
#664d14 73%,
#634811 97%) !important;
    width: 100%;
    color: #FFF;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 0.9em;
    letter-spacing: 0.62px;
    margin: 0.1em;
    border: none;
    &:hover {
      background: linear-gradient(to top, #f9cb10 0%, #aa6c00 100%) !important;
    }

    svg {
      width: 20px;
      margin-left: 0.25em;
    }

  }
  .btn-home{
    background: transparent !important;
    width: 100%;
    color: #FFF !important;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 1.3em;
    letter-spacing: 0.62px;
    margin: 0.1em;
    border: none;
    font-family: 'SoinSansNeue';
    padding: 10px;
    font-weight: lighter;
    cursor: pointer;
    text-decoration: none;
   

    svg {
      width: 20px;
      margin-left: 0.25em;
    }

  }
  .btn-group-card{
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  label{
    color: white;
  }
.btn-card{
    width: 200px;
    height: 200px;
    max-width: 250px;
    max-height: 250px;
    margin: 20px;
    border: 1px solid #695536 !important;
    color: white !important;
    }
.btn-card:hover{
    background: linear-gradient(to bottom, #CFA76B 0%, #695536 100%);
    
}
.btn-group-card-upload{
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.btn-card-upload{
  background: linear-gradient(to bottom, #CFA76B 0%, #695536 100%);
width: 100%;
height: 50px;
margin: 10px;
text-align: start;
border-radius: 10px !important;
}
.btn-upload{
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 15px;
}
.btn-upload:hover{
  background: linear-gradient(to bottom, #CFA76B 100%, #695536 0%);
}
.btn-back{
  float: left;
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 15px;
}
.green{
background: green;
}
.red{
background: rgb(96, 89, 89);
}
.btn-display{
  background: linear-gradient(to bottom, #CFA76B 100%, #695536 0%);
}
.btn-group-card-block{
  display: block !important;
  justify-content: center;
  align-items: center;
}
.btn-card-block{
  width: 300px;
  height: 50px;
  max-width: 450px;
  max-height: 100px;
  margin: 5px;
  border-radius: 10px;
  border: 1px solid #695536 !important;
  color: black !important;
  }

  .btn-main {
    background: #695536 !important;
    width: 100%;
    color: white !important;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 0.9em;
    letter-spacing: 0.62px;
    margin: 0.1em;
    border: none;
    &:hover {
      background: linear-gradient(to top, #f9cb10 0%, #aa6c00 100%);
    }

    svg {
      width: 20px;
      margin-left: 0.25em;
    }
  }

  .text-white{
    color: white;
  }

 

  .btn-secondary {
    background: transparent;
    border: 1px solid #FFFFFF;
    width: 100%;
    color: #FFF;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 0.6rem 0.75rem;
    font-size: 0.9em;
    letter-spacing: 0.62px;
    &:hover {
      background: lighten(#212123, 10%);
    }
  }
  .btn-display {
    background: white !important;
    border: 1px solid #FFFFFF;
    color: black !important;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 0.6rem 0.75rem;
    font-size: 0.9em;
    letter-spacing: 0.62px;
    &:hover {
      background: lighten(#212123, 10%);
    }
  }
  

  .help-block {
    color: #e74c3c;
    font-size: 0.85em;
    margin-top: 0.25em;
  }

  .alert { 
    font-family: 'SoinSansNeue-Bold';
    padding: 0.75em;
    font-size: 0.9em;
    color: black;
    margin-top: 1em;
    border-radius: 3px;
  }
  .alert-info {
    background-color: #3498db;
  }

  .alert-success {
    background-color: #4BB543;
  }
  .alert-danger {
    background: #a94442; 
    padding: 10px;
  }

  .modal-close {
    position: absolute;
    left: 1em;
    cursor: pointer;  
    svg {
      transform: scale(1.25);
    }
  }

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .text-white {
    color:#FFF;
  }
  .modal-container {

    width: 500px;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    border-radius: 6px;
    color: #666;
  }

  .modal-container-lg { 
    width: 650px;
  }

  .modal-container-sm { 
    width: 300px;
  }

  .modal-container-md { 
    width: 350px;
  }
  .modal-body {
    padding: 1em;
  }
  .modal-header {
    position: relative;
    padding: 1em;
    text-align: center;
    background-color: #4D4D4D;
    color: #FFF;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-family: 'SoinSansNeue-Bold';
    font-size: 0.9em;
    letter-spacing: 1.2px;
    padding: 0 1em;

    .close-container {
      position: absolute;
      right: 1em;
    }
  }

  label {
    letter-spacing: 0.65px;
  }
  
   /* width */
    ::-webkit-scrollbar {
       width: 5px;
       height: 5px;
    }
  /* Track */
    ::-webkit-scrollbar-track {
      background: lighten(#000a26, 20%)
   }

  /* Handle */
   ::-webkit-scrollbar-thumb {
      background: lighten(#000a26, 10%)
   }

  /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
       background: #555;
   }

  ::-webkit-scrollbar-corner { 
    background: lighten(#000a26, 20%);
  }

  @keyframes spin {
    0% {
     transform: rotate(0deg);
    }
    100% {
     transform: rotate(360deg);
    }
  }

  .spinner {
    animation: spin 2s linear infinite;
  }

 
</style>
