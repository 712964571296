<template>
	<div class="sidebar" :class="{ 'collapsed': isCollapsed }">
	  <!-- Sidebar Toggle Button -->
	  <div class="toggle-button" @click="toggleSidebar">
			<img class="icon" :src="require('../assets/images/left.png')" v-if="isCollapsed"/>
			<img class="icon" :src="require('../assets/images/right.png')" v-if="!isCollapsed"/>
		<!-- <i class="icon" :class="isCollapsed ? 'fa fa-chevron-right' : 'fa fa-chevron-left'"></i> -->
	  </div>
	  
	  <!-- Sidebar Navigation -->
	  <ul class="sidebar-nav">
		<div class="toggle-button" @click="toggleSidebar">
			<img class="icon" :src="require('../assets/images/left.png')" v-if="isCollapsed"/>
			<img class="icon" :src="require('../assets/images/right.png')" v-if="!isCollapsed"/>
		</div>
		 <div class="logo" :class="isCollapsed ? 'no' : 'yes'">
			<img :src="require('../assets/logo.png')"/>
		 </div>
		<div>
			<li ><img @click="toggleSidebar" :src="require('../assets/images/dashboard.png')" class="active-icon" v-if="isHome"/>
				<img @click="toggleSidebar" :src="require('../assets/images/dashboard.png')" v-else :class="isCollapsed ? 'fixed-icon' : 'expanded-icon'"/>
				<span :class="isCollapsed ? 'no-label' : 'label'" @click="goToHome">Dashboard</span>
			</li>
			<li >
				<img @click="toggleSidebar" :src="require('../assets/images/trans.png')" class="active-icon" v-if="isTxn"/>
				<img @click="toggleSidebar" :src="require('../assets/images/trans.png')" v-else :class="isCollapsed ? 'fixed-icon' : 'expanded-icon'"/>
				<span :class="isCollapsed ? 'no-label' : 'label'" @click="openFundHistory">My History</span>
			</li>
			<li >
				<img @click="toggleSidebar" :src="require('../assets/images/profile.png')" class="active-icon" v-if="isProfile"/>
				<img @click="toggleSidebar" :src="require('../assets/images/profile.png')" :class="isCollapsed ? 'fixed-icon' : 'expanded-icon'" v-else/>
				<span :class="isCollapsed ? 'no-label' : 'label'" @click="goToVerification">KYC</span>
			</li>
			<li  >
				<img @click="toggleSidebar" :src="require('../assets/images/contacts.png')" class="active-icon" v-if="isContacts"/>
				<img @click="toggleSidebar" :src="require('../assets/images/contacts.png')" :class="isCollapsed ? 'fixed-icon' : 'fixed-icon'" v-else/>
				<span :class="isCollapsed ? 'no-label' : 'label'" @click="openContacts">My Contacts</span>
			</li>
			<li  :class="isCollapsed ? 'no-label' : 'label'">
				<img @click="toggleSidebar" :src="require('../assets/images/change pin.png')" class="active-icon" v-if="isChangePin"/>
				<img @click="toggleSidebar" :src="require('../assets/images/change pin.png')" :class="isCollapsed ? 'fixed-icon' : 'expanded-icon'" v-else/>
				<span :class="isCollapsed ? 'no-label' : 'label'" @click="openChangePin">Change PIN</span>
			</li>
			<li :class="isCollapsed ? 'no-label' : 'label'">
				<img @click="toggleSidebar" :src="require('../assets/images/2fa.png')" class="active-icon" v-if="is2fa"/>
				<img @click="toggleSidebar" :src="require('../assets/images/2fa.png')" :class="isCollapsed ? 'fixed-icon' : 'fixed-icon'" v-else/>
				<span :class="isCollapsed ? 'no-label' : 'label'" @click="open2fa">2FA verification</span>
			</li>
			
			<li >
				<img @click="toggleSidebar" :src="require('../assets/images/coldstorage.png')" class="active-icon" v-if="isHome"/>
				<img @click="toggleSidebar" :src="require('../assets/images/coldstorage.png')" v-else :class="isCollapsed ? 'fixed-icon' : 'expanded-icon'"/>
				<span :class="isCollapsed ? 'no-label' : 'label'" @click="openComingSoon">Cold Storage</span>
			</li>
			<li  >
				<img @click="toggleSidebar" :src="require('../assets/images/redeem.png')" class="active-icon" v-if="isHome"/>
				<img @click="toggleSidebar" :src="require('../assets/images/redeem.png')" v-else :class="isCollapsed ? 'fixed-icon' : 'expanded-icon'"/>
				<span :class="isCollapsed ? 'no-label' : 'label'" @click="openRedeem">Redeem</span>
			</li>
			
			<!-- <li v-for="item in navigationItems" :key="item.id">
				<router-link :to="item.path"><img :src="item.imageUrl" :class="isCollapsed ? 'fixed-icon' : 'expanded-icon'"/><span :class="isCollapsed ? 'no-label' : 'label'">{{ item.label }}</span> </router-link>
			  </li> -->
		</div>
		
	  </ul>
	  <ul class="sidebar-nav">
		<li @click="contactSupport"><a href="https://t.me/CoexstarSupport" target="_blank"><img :src="require('../assets/images/support.png')" class="active" v-if="isHome"/><img :src="require('../assets/images/support.png')" v-else :class="isCollapsed ? 'fixed-icon' : 'expanded-icon'"/><span :class="isCollapsed ? 'no-label' : 'label'">Contact Support</span></a></li>
		<li @click="openLogout" :class="isCollapsed ? 'no-label' : 'label'"><img :src="require('../assets/images/logout.png')" class="active" v-if="isLogout"/><img :src="require('../assets/images/logout.png')" :class="isCollapsed ? 'fixed-icon' : 'expanded-icon'" v-else/><span :class="isCollapsed ? 'no-label' : 'label'">Logout</span></li>
	  </ul>
	  <Logout @close="openLogout" v-if="showLogoutModal"/>
      <ChangePin @close="openChangePin" v-if="showChangePin"/>
	  <ContactList @close="openContacts" v-if="showContacts"/>
	  <Twofa @close="open2fa" v-if="show2fa"/>
	  <Redeem @close="openRedeem" v-if="showRedeem"/>
	  <ComingSoon @close="openComingSoon" v-if="showComingSoon"/>

	</div>
  </template>
  
  <script>
	import ChangePin from "../components/modal/ChangePin.vue";
	import Logout from "../components/modal/Logout";
	import ContactList from "../components/modal/ContactList.vue";
	import Twofa from "../components/modal/Twofa.vue";
	import Redeem from "../components/modal/Redeem.vue";
	import ComingSoon from "../components/modal/ComingSoon.vue";

  export default {
	name: 'TheNavigation',
	components:{
		ChangePin,
		Logout,
		ContactList,
		Twofa,
		Redeem,
		ComingSoon
	},
	data() {
	  return {
		showChangePin: false,
		showContacts: false,
      	showLogoutModal: false,
		show2fa: false,
		showRedeem: false,
		isCollapsed: true,
		isTxn: false,
		isHome: false,
		isProfile: false,
		isChangePin: false,
		isContacts: false,
		is2fa: false,
		isLogout: false,
		path:'',
		showComingSoon: false
		
	  };
	},
	computed: {
      show() {
        return this.$store.state.show;
      },
      init(){
        return this.$store.state.init;
      }
    },
	methods: {
		openComingSoon(){
		this.showComingSoon = !this.showComingSoon
		},
	  toggleSidebar() {
		this.isCollapsed = !this.isCollapsed;
	  },
	  openLogout(){
        this.showLogoutModal = !this.showLogoutModal;
      },
      openProfile(){
		window.location.href = "/#/" + this.init.currentLocation.toLowerCase() + "/" + 'complete-kyc/';
		//window.location.href="/#/complete-kyc/"
      },
      goToVerification(){
		window.location.href = "/#/" + this.init.currentLocation.toLowerCase() + "/" + 'verification/';
		//window.location.href="/#/verification/"
      },
      openFundHistory() {
		window.location.href = "/#/" + this.init.currentLocation.toLowerCase() + "/" + 'fund-history/';
        //window.location.href = "/#/fund-history/"
      },
      openChangePin() {
        this.showChangePin = !this.showChangePin;
        
      },
	  open2fa(){
		this.show2fa = !this.show2fa;
	  },
	  openRedeem(){
		this.showRedeem = !this.showRedeem;
	  },
	  goToHome(){
		window.location.href = "/#/"
		window.location.reload();
	  },
	  openContacts(){
		this.showContacts = !this.showContacts;
       
	  },
	//   contactSupport(){
	// 	window.location.href="https://t.me/CoexstarSupport"
	//   }
	},
	mounted(){
		console.log(this.$route.path)
		if(this.$route.path == '/fund-history/'){
			this.isTxn = true
		}
		if(this.$route.path == '/verification/' || this.$route.path == '/kyc'){
			this.isProfile = true
		}
		
		
	
	}
  };
  </script>
  
  <style>
	.active-icon{
		filter: invert(70%);
		
	}
.fixed-icon{
	width: 1.5rem !important;
	padding: 2px;
	object-fit: contain;
	cursor: pointer;
}
.expanded-icon{
	object-fit: contain;
	padding: 2px;
	width: 1.5rem !important;
	cursor: pointer;
}

.label{
	color: white;
	padding: 2px;
	cursor: pointer;
}
.no-label{
	display: none;

}
.fixed-icon-disabled{
	width: 1.5rem !important;
	padding: 2px;
	object-fit: contain !important;
	cursor: not-allowed;
}
.expanded-icon-disabled{
	object-fit: contain !important;
	padding: 2px;
	width: 1.5rem !important;
	cursor: not-allowed;
}
.label-disabled{
	color: white;
	padding: 2px;
	cursor: not-allowed;
}
.no-label-disabled{
	cursor: not-allowed;
	display: none;

}
.logo img{
	width: 10rem !important;
	padding: 20px;
}
.no{
	display: none !important;
}

  .sidebar {
	width: 250px;
	height: 100vh;
	background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
	position: fixed;
	top: 20px;
	left: 0;
	transition: width 0.7s ease;
	z-index:999;
	
  }
  .disabled{
	cursor: not-allowed !important;
  }
  .sidebar.collapsed {
	width: 50px; /* Change this width to adjust the collapsed sidebar size */
	position: fixed;
  }
  
  .sidebar-nav {
	padding-left: 10px;
	padding-top: 50px;
	top: 200px;

  }
  
  .sidebar-nav li {
	margin-bottom: 10px;
	display: flex;
  }
  
  .toggle-button {
	width: 50px;
	height: 50px;
	background: #695536 !important;
	position: fixed;
	top: 160px;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
  }
  
  .icon {
	font-size: 20px;
  }
  @media only screen and (min-width: 1008px) {
  

   }
  
   @media only screen and (min-width: 275px) and (max-width: 1007px) {
   
    .sidebar.collapsed {
		width: 40px !important; 
		position: fixed;
  	}
	  .fixed-icon{
		width: 1.5rem !important;
		padding: 2px;
		object-fit: contain;
		cursor: pointer;
	}
	
	
   
  }
  </style>
  