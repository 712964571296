import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import common from './common'
import restful from './restful'
import axios from 'axios'
import VueAxios from 'vue-axios' 
import CanvasJSChart from '@canvasjs/vue-charts'
import VueQrcode from '@chenfengyuan/vue-qrcode'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


axios.defaults.headers.common['blox-authz'] = localStorage.getItem("tokenId");

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.use(VueQrcode.name, VueQrcode)
app.use(CanvasJSChart)
app.config.globalProperties.$c = common.common.methods;
app.config.globalProperties.$r = restful.rest;
app.mount('#app')
