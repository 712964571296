<template>
  <div>
  <img :src="require('@/assets/bg.jpg')" class="bg" />
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container modal-container-md">
          <div class="modal-header">
            
          </div>
          <div class="logo-wrapper">
            <img class="logo" :src="require('@/assets/logo.png')" >
          </div>
          <h4 class="container">FORGOT PIN</h4>

          <div class="modal-body">
            
            <form  v-on:submit.prevent="onForgotPassword">
            <div class="alert" :class="[isError ? 'alert-danger' : 'alert-success' ]" v-if="errors.login.show">
              {{ errors.login.msg }}
            </div>
            <div class="btn-group email-or-mobile">
              <button type="button" class="btn " v-bind:class="{ active: selected === 'mobile' }" v-on:click="toggleLoginOption('mobile')">Mobile Number</button>
              <button type="button" class="btn " v-bind:class="{ active: selected === 'email' }" v-on:click="toggleLoginOption('email')">Email Address</button>
            </div>
            <div class="form-group" v-if="selected =='email'">
              <label for="formGroupExampleInput">Enter your registered Email Address:</label>
              <input type="text" class="form-control" id="formGroupExampleInput" v-model="forgot.loginId">
              <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
            </div>
            <div class="form-group mobile-container" v-if="selected =='mobile' ">
              <label for="loginId">Enter your registered Mobile Number:</label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <button class="btn btn-flag" type="button" v-on:click="toggleCountryList">
                    <img class="default-flag" :src="require('@/assets/signup_flags/' + country + '.png' )">
                    <span class="text-white">{{ mobileCode }}</span>
                  </button>
                </div>
                <input type="tel" class="form-control" v-model="forgot.loginId" @keypress="isNumber($event)">
              </div>
              <div class="country-list" v-if="showCountry">
                <div class="row">
                  <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountry(l)">
                    <div>
                      <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                    </div>
                    <div class="country-name">
                      <span> {{ l.country }} </span>
                    </div>
                  </div>
                </div>
              </div>
             
            </div>

           
         
            <div class="form-group submit-button text-center pt-3">
              <button class="btn btn-main" id="forgotBtn">Submit </button>
            </div>
            <a href="javascript:;" v-on:click="toLogin" class="forgot">Back to login</a>
          </form>

          </div>
        </div>
      </div>
    </div>
  </transition>
</div>
</template>

<script>

  export default {
  
    
    name: 'ForgotPinComponent',
    components: {
    },
    computed: {
      show() {
        return this.$store.state.show;
      },
    },
    data: function() {
      return {
        login: {
          loginId: "",
          loginPass: "",
          captcha: "",
          loginHost: "overview.coexstar.com"
        },
        forgot: {
          loginId: "",
        },
        errors: {
          login: {
            msg: "",
            show: false,
            loginId: "",
            loginPass: "",
            captcha: ""
          }
        },
        showCountry: false,
        selected: 'mobile',
        country: 'GB',
        mobileCode: '44',
        hideOrShow: "Show",
        list: {
          mobileCodes: []
        },
        isError: false,
        captchaString: ""
      }
    },
    computed: {
      show() {
        return this.$store.state.show;
      },
      init() {
          return this.$store.state.init;
      },
    },
    methods: {
      signupModal() {
				this.show.signup = true
				this.show.login = false
			},
      toLogin(){
        this.$emit("close");
      },

      toggleCountryList: function() {
      this.showCountry = !this.showCountry;
    },
    toggleLogin: function(state) {
      this.errors.login.show = false;
      this.errors.login.msg = "";
      this.login.state = state;
    },
    toggleShowPassword: function() {
      this.login.showPassword = !this.login.showPassword;

      if (this.login.showPassword) {
        document.getElementById('inputPass').type = 'text';
        this.hideOrShow = "Hide"
      } else {
        document.getElementById('inputPass').type = 'password';
        this.hideOrShow = "Show"
      }
    },

    toggleLoginOption(selected) {
      this.selected = selected;
      this.login.loginId = "";

      this.errors.login.loginId = '';
      this.errors.login.loginPass = '';

      if (selected == 'email') {
        this.login.loginId = "";
      }
    },

    gotoSignup: function() {
      window.location.href = "/#/" + this.init.currentLocation.toLowerCase() + "/" + 'signup/';
    },
    selectCountry: function(c) {
      this.showCountry = false;
      this.country = c.countryCode;
      this.mobileCode = c.mobileCode;
    },

    validateEmail: function(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },

    onLogin: function() {

      let hasError = false;

      if (this.selected == 'mobile') {
        if (this.login.loginId == '' || this.login.loginId == undefined || this.login.loginId == null) {



          this.errors.login.loginId = "Please provide a valid  Mobile no.";
          hasError = true;
        } else {
          if (this.login.loginId.length < 10) {

            this.errors.login.loginId = "Please provide a valid  Mobile no.";
            hasError = true;
          }

          if (this.login.loginId.substring(0, 1) === '0') {
            this.errors.login.loginId = "Please remove 0 in front of your mobile number";
            hasError = true;
          }
        }


      }

      if (this.selected == 'email') {
        if (this.login.loginId == '' || this.login.loginId == undefined || this.login.loginId == null) {



          this.errors.login.loginId = "Please provide a valid Email Address.";
          hasError = true;
        }

        if (!this.validateEmail(this.login.loginId)) {

          this.errors.login.loginId = "Please provide a valid Email Address.";
          hasError = true;
        }
      }


      if (this.login.loginPass == '' || this.login.loginPass == undefined || this.login.loginPass == null) {
        this.errors.login.loginPass = "Please provide valid PIN.";
        hasError = true;
      } else {
        if (this.login.loginPass.length < 6) {
          this.errors.login.loginPass = "PIN must be 6-digits.";
          hasError = true;
        }
      }

      if (this.login.captcha == '' || this.login.captcha == undefined || this.login.captcha == null) {
        this.errors.login.captcha = "Captcha is required.";
        hasError = true;
      }

      if (hasError) {
        return;
      }

      this.errors.login.show = false;

      let loginId = "";

      if (this.selected == 'mobile') {
        loginId = this.mobileCode + this.login.loginId
      } else {
        loginId = this.login.loginId
      }

      const params = {
        loginHost: this.login.loginHost,
        loginId: loginId,
        pin: this.login.loginPass,
        captcha: this.login.captcha,
      }

      const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');


      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        data,
        url: '/cx/login/pin',
      };

      this.axios(options)
        .then((response) => {
          //console.log(response.data.tokenId)

          let loginData = loginId + ":" + this.login.loginPass + ":"  + this.login.loginHost

           let encodedData = window.btoa(loginData);

           localStorage.setItem("authz", encodedData)
           
          localStorage.setItem("tokenId", response.data.tokenId);
          window.location.href = "/#/";

          this.isError = false;
        }).catch((err) => {
          this.isError = true;
          this.errors.login.show = true;
          if (err.response.data) {

            if (err.response.data.fieldErrors) {

              err.response.data.fieldErrors.forEach((error) => {

                switch (error.field) {


                  default:
                    this.errors.login[error.field] = error.defaultMessage;
                    break;
                }
              });

            } else {

              this.errors.login.msg = err.response.data.msgText;
            }
          }
          setTimeout(()=>{
                      this.clearError()
            },3000);

        })

    },

    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getMobileCodes: function() {
      this.axios.get('/assets/js/mobilecodes.json')
        .then((response) => {
          this.list.mobileCodes = response.data;
        }).catch((err) => {
          console.log(err);

        })
    },

    getCaptcha: function() {
      this.axios.get('/cx/login/captcha?fontColor=FFFFFF')
        .then((response) => {
          console.log(response)

          this.captchaString = response.data;
        }).catch((err) => {
          console.log(err);
          setTimeout(() => {
            this.errors.login.show = false;
            this.clearError()
          }, 3000)


        })
    },
    selectCountry: function(c) {
      this.showCountry = false;
      this.country = c.countryCode;
      this.mobileCode = c.mobileCode;
    },
    onForgotPassword: function() {
      
      this.errors.login.show = false;

      let loginId = "";

      if (this.selected == 'mobile') {
        loginId = this.mobileCode + this.forgot.loginId
      } else {
        loginId = this.forgot.loginId
      }

      const params = {
        siteHost: this.login.loginHost,
        loginId: loginId,
      }

      const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');


      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        data,
        url: '/cx/processforgotpin',
      };

      this.axios(options)
        .then(() => {
          this.errors.login.show = true;
          this.isError = false;
          this.errors.login.msg = "Temporary PIN has been sent";

          this.forgot.loginId = '';

          console.log(this.errors.login.msg)
        }).catch((err) => {
          this.isError = true;
          this.errors.login.show = true;
          if (err.response.data) {

            if (err.response.data.fieldErrors) {

              err.response.data.fieldErrors.forEach((error) => {

                switch (error.field) {


                  default:
                    this.errors.login[error.field] = error.defaultMessage;
                    break;
                }
              });

            } else {

              this.errors.login.msg = err.response.data.msgText;
            }
          }
        })


      setTimeout(() => {
         this.errors.login.show = false;
      }, 3000)
    },
    clearError(){
     
     this.errors.login.show = false;
     this.errors.login.loginId = '';
     this.errors.login.msg = '';
     this.errors.login.captcha = '';
     this.errors.login.loginPass = '';
 
 },
    },
    mounted() { 
      this.getMobileCodes();
      this.getCaptcha();
    },
    watch: {
      
     'login.loginId': function(newValue, oldValue) {

        if (newValue != oldValue) {

          this.errors.login.loginId = '';
          this.errors.login.msg = '';
          this.errors.login.show = false;
        }
      },

      'login.loginPass': function(newValue, oldValue) {
        if (newValue != oldValue) {
          this.errors.login.loginPass = '';
          this.errors.login.msg = '';
          this.errors.login.show = false;
        }
      },

      'login.captcha': function(newValue, oldValue) {
        if (newValue != oldValue) {
          this.errors.login.captcha = '';
          this.errors.login.msg = '';
          this.errors.login.show = false;
        }
      },
    }
  }
</script>


<style scoped lang="scss">
  
  .modal-container {
    background: rgb(19,59,121);
  background: linear-gradient(200deg, rgba(19,59,121,1) 0%, rgba(12,12,37,1) 100%);
  }

  .modal-body {
    padding: 2em;
    
  }

  .logo-wrapper {
    display:flex;
    align-items: center;
    justify-content:center;
    padding: 1em;
    .logo {
      width: 15em;
    }
  }
  .container{
    display:flex;
    align-items: center;
    justify-content:center;
    color:#fff;
  }
  .captcha-wrapper {
    border: 1px solid transparent !important;
    border-radius: 0.25rem;
    padding: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 50%;
      object-fit: cover;
    }
    input {
      height: 60px;
      border-left: none;
      border-top: none;
      border-right: none;
      border-bottom: 2px solid #fff;
      border-radius: 0;
      font-size: 30px;
      padding: 5px;
      background: none;
      color: #fff;
      width: 60px;
      outline: none;
    }
  }

  #btnShow {
    font-family: "SoinSansNeue-Bold";
  }

  label {
    font-family: "SoinSansNeue-Bold";
    color: #FFFFFF;
  }

  h4 {
    font-family: "SoinSansNeue-Bold";
  }

  #signupBtn {
    border: 1px solid #0074b8;
    color: #fff;
    width: 100%;
    background: transparent;
  }
  .forgot {
    display: flex;
    justify-content: flex-end;
  }
  a {
    margin-top: 0.5em;
    color: #fff;
    text-decoration: underline;
  }
  .btn-flag {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
    img {
      width: 200px;
    }
  }

  .mobile-container {
    position: relative;
    padding: 5px;
    .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }

    .country-list {
      position: absolute;
      background: #ccc;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
      .row {
        margin: 0 !important;
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
          &:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
        }
      }
    }
  }

  .flag-list-img {
    width: 33px;
  }

  .email-or-mobile {
    font-family: "SoinSansNeue-Bold";
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;

    .btn {
      background: transparent;
      color: #898989;
      border: none;
      outline: none;
      box-shadow: none;
      border-radius: 0;

      &.active {
        font-family: "SoinSansNeue-Bold";
        color: #FFFFFF;
        border-bottom: 1px solid #FFFFFF;
      }
    }
  }
  .main-wrapper {
    display: flex;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 10em;
  }
.form-group{
  padding: 5px !important;
}
  .wrapper {
    padding: 2em;
    background: rgb(1, 79, 136);
    background: linear-gradient(
      120deg,
      rgba(1, 79, 136, 1) 0%,
      rgba(1, 11, 22, 1) 45%,
      rgba(1, 11, 22, 1) 55%,
      rgba(1, 79, 136, 1) 100%
    );

    display: flex;
    flex-direction: column;
    width: 22.5em;
    z-index: 2;
    border-radius: 0.75em;
    form {
      color: #fff;
    }
  }
  #lnkSignup {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

 
  @media (max-width: 375px) {
    .wrapper {
      width: 19em !important;
    }
  }

  @media only screen and (min-width: 641px) and (max-width: 1024px) {
    .main-wrapper {
      margin-right: 0;
      align-items: center;
    }
  }

  @media (max-width: 39.9375em) {
    .main-wrapper {
      margin-right: 0;
      align-items: center;
    }
    .wrapper {
      width: 20em;
      padding: 1em !important;
      h4 {
        font-size: 1.25em;
      }

      .email-or-mobile {
        .btn {
          font-size: 1em;
        }
      }
    }

    button,
    input {
      font-size: 85%;
    }

    .mobile-container {
      .default-flag {
        width: 25px;
      }
    }
  }

</style>