<template>
    <div class="backdrop">
      <div class="b-modal">
          <div class="modal-header text-right">
              <h5 class="mt-2 text-white">Add to my Contact List</h5>
              <p @click="closeModal" class="x">x</p>
          </div>
        <div class="modalBody">
         
             <div v-if="step == '2'" class="mt-5">
                  <p class="text-white">Successfully added.</p>
                  <p>
                  <button class="btn btn-main submit" @click="closeModal">Done</button>
                  </p>
              </div>

              <div v-if="step == '1'">

                <form  v-on:submit.prevent="submitContact">
                  
                  <div class="btn-group email-or-mobile">
                    <button type="button" class="btn " v-bind:class="{ active: selected === 'mobile' }" v-on:click="toggleLoginOption('mobile')">Mobile Number</button>
                    <button type="button" class="btn " v-bind:class="{ active: selected === 'email' }" v-on:click="toggleLoginOption('email')">Email Address</button>
                  </div>
                  <div class="form-group" v-if="selected =='email'">
                    <label for="formGroupExampleInput">Enter a registered Email Address:</label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="contactDetailsEmail">
                    <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
                  </div>
                  <div class="form-group mobile-container" v-if="selected =='mobile' ">
                    <label for="loginId">Enter a registered Mobile Number:</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <button class="btn btn-flag" type="button" v-on:click="toggleCountryList">
                          <img class="default-flag" :src="require('@/assets/signup_flags/' + country + '.png' )">
                          <span class="text-white">{{ mobileCode }}</span>
                        </button>
                      </div>
                      <input type="tel" class="form-control" v-model="contactDetails" @keypress="isNumber($event)">
                    </div>
                    <div class="country-list" v-if="showCountry">
                      <div class="row">
                        <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountry(l)">
                          <div>
                            <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                          </div>
                          <div class="country-name">
                            <span> {{ l.country }} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
                   
                  </div>
      
                 
               
                  <div class="form-group submit-button text-center pt-3">
                    <button class="btn btn-main" id="forgotBtn">Submit </button>
                  </div>
                
                </form>
               
              </div>
              
  
      
           
         
        </div>
      </div>
    </div>
  </template>
  
  <script>

    export default {
    
      
      name: 'AddContactModal',
      components: {
      },
      computed: {
        show() {
          return this.$store.state.show;
        },
      },
      data: function() {
        return {
          step: '1',
          contactDetails:'',
          
          errors: {
            login: {
              msg: "",
              show: false,
              loginId: "",
              loginPass: "",
              captcha: ""
            }
          },
          showCountry: false,
          selected: 'mobile',
          country: 'GB',
          mobileCode: '44',
          hideOrShow: "Show",
          contactDetailsEmail:'',
          list: {
            mobileCodes: []
          },
          isError: false,
          captchaString: "",
          params:{}
        }
      },
      methods: {
       
  
        toggleCountryList: function() {
        this.showCountry = !this.showCountry;
      },
      toggleLogin: function(state) {
        this.errors.login.show = false;
        this.errors.login.msg = "";
        this.login.state = state;
      },
      toggleShowPassword: function() {
        this.login.showPassword = !this.login.showPassword;
  
        if (this.login.showPassword) {
          document.getElementById('inputPass').type = 'text';
          this.hideOrShow = "Hide"
        } else {
          document.getElementById('inputPass').type = 'password';
          this.hideOrShow = "Show"
        }
      },
  
      toggleLoginOption(selected) {
        this.selected = selected;
        this.contactDetails = "";
  
        this.errors.login.loginId = '';
        this.errors.login.loginPass = '';
  
        if (selected == 'email') {
          this.contactDetails = "";
        }
      },
  
      gotoSignup: function() {
        window.location.href = "/#/" + this.init.currentLocation.toLowerCase() + "/" + 'signup/';
      },
      selectCountry: function(c) {
        this.showCountry = false;
        this.country = c.countryCode;
        this.mobileCode = c.mobileCode;
      },
  
      validateEmail: function(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      },
  
      submitContact: function() {
  
        let hasError = false;
  
        if (this.selected == 'mobile') {
          if (this.contactDetails == '' || this.contactDetails == undefined || this.contactDetails == null) {
  
  
  
            this.errors.login.loginId = "Please provide a valid  Mobile no.";
            hasError = true;
          } else {
            if (this.contactDetails.length < 10) {
  
              this.errors.login.loginId = "Please provide a valid  Mobile no.";
              hasError = true;
            }
  
            if (this.contactDetails.substring(0, 1) === '0') {
              this.errors.login.loginId = "Please remove 0 in front of your mobile number";
              hasError = true;
            }
          }
  
  
        }
  
        if (this.selected == 'email') {
          if (this.contactDetailsEmail == '' || this.contactDetailsEmail == undefined || this.contactDetailsEmail == null) {
  
  
  
            this.errors.login.loginId = "Please provide a valid Email Address.";
            hasError = true;
          }
  
          if (!this.validateEmail(this.contactDetailsEmail)) {
  
            this.errors.login.loginId = "Please provide a valid Email Address.";
            hasError = true;
          }
        }
  
  
    
  
        if (hasError) {
          return;
        }
  
        this.errors.login.show = false;
  
        let params = {};
  
        if (this.selected == 'mobile') {
          params.contactMobile = this.mobileCode + this.contactDetails
        } else {
          params.contactEmail = this.contactDetailsEmail
        }
        
      
        const options = {
          method: 'POST',
          headers: {
            'content-type': 'application/x-www-form-urlencoded'
          },
          params,
          url: '/cx/addcontact',
        };
  
        this.axios(options)
          .then((response) => {
           this.step = '2';
          
           
          }).catch((err) => {
            this.errors.login.loginId = err.response.data.msgText
              if(err.response.data.fieldErrors) {
                 this.errors.login.loginId = err.response.data.fieldErrors[0].defaultMessage
                 setTimeout( () => {
                  hasError = false;
                  this.errors.login.loginId = ""
                  },2000)
              }
              
              
  
          })
  
      },
  
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      getMobileCodes: function() {
        this.axios.get('/assets/js/mobilecodes.json')
          .then((response) => {
            this.list.mobileCodes = response.data;
          }).catch((err) => {
            console.log(err);
  
          })
      },
      closeModal(){
              // this.$emit("close")
             window.location.reload();
          
          },
      getCaptcha: function() {
        this.axios.get('/cx/login/captcha?fontColor=FFFFFF')
          .then((response) => {
            console.log(response)
  
            this.captchaString = response.data;
          }).catch((err) => {
            console.log(err);
            setTimeout(() => {
              this.errors.login.show = false;
              this.clearError()
            }, 3000)
  
  
          })
      },
      selectCountry: function(c) {
        this.showCountry = false;
        this.country = c.countryCode;
        this.mobileCode = c.mobileCode;
      },
      onForgotPassword: function() {
        
        this.errors.login.show = false;
  
        let loginId = "";
  
        if (this.selected == 'mobile') {
          loginId = this.mobileCode + this.forgot.loginId
        } else {
          loginId = this.forgot.loginId
        }
  
        const params = {
          siteHost: this.login.loginHost,
          loginId: loginId,
        }
  
        const data = Object.keys(params)
          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
          .join('&');
  
  
        const options = {
          method: 'POST',
          headers: {
            'content-type': 'application/x-www-form-urlencoded'
          },
          data,
          url: '/cx/processforgotpin',
        };
  
        this.axios(options)
          .then(() => {
            this.errors.login.show = true;
            this.isError = false;
            this.errors.login.msg = "Temporary PIN has been sent";
  
            this.forgot.loginId = '';
  
            console.log(this.errors.login.msg)
          }).catch((err) => {
            this.isError = true;
            this.errors.login.show = true;
            if (err.response.data) {
  
              if (err.response.data.fieldErrors) {
  
                err.response.data.fieldErrors.forEach((error) => {
  
                  switch (error.field) {
  
  
                    default:
                      this.errors.login[error.field] = error.defaultMessage;
                      break;
                  }
                });
  
              } else {
  
                this.errors.login.msg = err.response.data.msgText;
              }
            }
          })
  
  
        setTimeout(() => {
           this.errors.login.show = false;
        }, 3000)
      },
      clearError(){
       
       this.errors.login.show = false;
       this.errors.login.loginId = '';
       this.errors.login.msg = '';
       this.errors.login.captcha = '';
       this.errors.login.loginPass = '';
   
   },
      },
      mounted() { 
        this.getMobileCodes();
        this.getCaptcha();
      },
      watch: {
        
       'login.loginId': function(newValue, oldValue) {
  
          if (newValue != oldValue) {
  
            this.errors.login.loginId = '';
            this.errors.login.msg = '';
            this.errors.login.show = false;
          }
        },
  
        'login.loginPass': function(newValue, oldValue) {
          if (newValue != oldValue) {
            this.errors.login.loginPass = '';
            this.errors.login.msg = '';
            this.errors.login.show = false;
          }
        },
  
        'login.captcha': function(newValue, oldValue) {
          if (newValue != oldValue) {
            this.errors.login.captcha = '';
            this.errors.login.msg = '';
            this.errors.login.show = false;
          }
        },
      }
    }
  </script>
  
  <style scoped>
  .backdrop {
      top: 0;
      right:0;
      position: fixed;
      width: 100vw;
      height: 100vh;
      z-index: 98;
      background: rgba(0, 0, 0, 0.6);
  
  }
  .b-modal {
    width: 620px;
      height: auto;
      margin: 50px auto;
      background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
      border-radius: 20px;
      border-color:white;
      border-width:2px;
      z-index: 99;
      overflow-x: hidden !important;
      color:black;
      max-height: calc(100vh - 110px);
      overflow-y: auto;
  }
  
  .modal-header {
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
      margin-right:0px;
      font-size:25px;
      display:flex;
      justify-content:space-between;
  }
  
  .modal-title{
      margin: 0 auto;
      color:white;
  }
  .mobile-container {
    position: relative;
    padding: 5px;
    .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }

    .country-list {
      position: absolute;
      background: #ccc;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
      .row {
        margin: 0 !important;
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
          &:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
        }
      }
    }
  }

  .flag-list-img {
    width: 33px;
  }

  .email-or-mobile {
    font-family: "SoinSansNeue-Bold";
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;

    .btn {
      background: transparent;
      color: #898989;
      border: none;
      outline: none;
      box-shadow: none;
      border-radius: 0;

      &.active {
        font-family: "SoinSansNeue-Bold";
        color: #FFFFFF;
        border-bottom: 1px solid #FFFFFF;
      }
    }
  }
  .modalBody {
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
      width:100%;
      height: 300px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
  }
  
  .buttonWrapper {
      width:100%;
      margin-top: 10px;
      padding-top: 15px;
  }
  
  .x {
      cursor:pointer;
  }
  .red{
      color: red;
  }

 
  
  
      @media screen and (max-width: 1100px) {
      
            
      }
     @media screen and (max-width: 800px) {
       
    }
  
      @media screen and (max-width: 600px) {
         
          
      }
  
      @media screen and (min-width: 280px) and (max-width: 420px) {
  
         .b-modal {
              width: 350px;
              height: auto;
              margin-top: 50px;
              background: rgb(13, 2, 57);
              border-radius: 20px;
              border-color:white;
              border-width:2px;
              z-index: 1;
          }
  
          .modalBody {
            background: rgb(13, 2, 57);
              width:100%;
              padding: 20px 10px;
              border-radius: 10px;
          }
          
  
    
        
  
  
      }
  
      @media screen and (min-width: 480px) and (max-width: 580px) {
          .b-modal {
                  width: 405px;
          }
  
      
       
      }
      .wrapper{
        display: flex;
         padding: 2em;
        justify-content: center !important;
        margin: 0 auto !important;
    }
   
      .product-grid {
        display: flex;
       
      }

    .product-card {
        background-image: linear-gradient(
                180deg,
              #10184d 29%,
              #00103d 59%,
                #010c2a 88%
        );
        box-shadow:0 0 30px rgba(0,0,0,0.3);
        border: 1px solid transparent;
        border-radius: 12px;
        width: 7rem;
        height: auto;
        text-align: center;
        margin: 10px !important;
        color: white;
        cursor: none;
    
    }


    .product-image {
      padding: 10px 0px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        margin: 0 auto;
        background-color: none !important;
      width: 100%;
      max-width: 80px;
      height: auto;
      margin-bottom: 10px;
    }
  </style>