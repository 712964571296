import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../components/modal/Login.vue';
import Signup from '../components/modal/Signup.vue';

const routes = [
  {
    path: '/:countryCode',
    name: 'Index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "index" */ '@/components/Index')
    }
  },
  {
    path: "/:countryCode/login",
    name: "Login",
    component: Login,
    props: true,
  },
  {
    path: "/:countryCode/signup",
    name: "Signup",
    component: Signup,
    props: true,
  },
  // {
  //   path: '/forgot-pin',
  //   name: 'ForgotPin',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(/* webpackChunkName: "forgot-pin" */ '@/components/ForgotPin')
  //   }
  // },
  {
    path: '/authz/:authz',
    name: 'Authz',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "index" */ '@/components/Index')
    }
  },

  {
    path: "/confirmemail/:utoken",
    name: 'ConfirmEmail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "index" */ '@/components/Index')
    }
  },
  {
    path: "/confirmphone/:utoken",
    name: 'ConfirmPhone',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "index" */ '@/components/Index')
    }
  },
  {
    path: '/:countryCode/setup-profile',
    name: 'SetupProfile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "setup-profile" */ '@/components/SetupProfile')
    }
  },
  {
    path: '/:countryCode/corporate-setup-profile/:step',
    name: 'CorporateSetup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "corporate-setup-profile" */ '@/components/CorporateSetup')
    }
  },
  {
    path: '/:countryCode/individual-setup-profile/:step',
    name: 'Individual',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "individual-setup-profile" */ '@/components/Individual')
    }
  },
  {
    path: '/:countryCode/upload-document',
    name: 'UploadDocument',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "upload-document" */ '@/components/UploadDocument')
    }
  },
  {
    path: '/:countryCode/individual-associates',
    name: 'BoardOfDirectors',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "individual-associates" */ '@/components/BoardOfDirectors')
    }
  },
  {
    path: '/:countryCode/kyc',
    name: 'Kyc',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "kyc" */ '@/components/Kyc')
    }
  },
  {
    path: '/:countryCode/verification',
    name: 'Verification',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "verification" */ '@/components/Verification')
    }
  },
  
  {
    path: '/:countryCode/corporate/:step',
    name: 'Corporate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "corporate" */ '@/components/Corporate')
    }
  },
 
  {
    path: '/:countryCode/fund-history',
    name: 'FundHistory',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "trade-history" */ '@/components/FundHistory')
    }
  },
  {
    path: "/confirmemail/:utoken",
    name: 'EmailActivation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "corporate-setup-profile" */ '@/views/EmailActivation')
    }
  },
  {
    path: "/confirmmphone/:utoken",
    name: 'MobileActivation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "corporate-setup-profile" */ '@/views/MobileActivation')
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
