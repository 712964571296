<template>

  <div class="backdrop">
    <div class="b-modal">
        <div class="modal-header text-center">
          <h4 class="text-uppercase text-white text-center">Change 2FA Verification</h4>

        </div>
        <div class="modalBody">
          <div style="padding: 1em">
            <div v-if="state == 'successful'">
              
                <div class="coin-container">
                  <div> 
                    <div class="text-center">	
                      <p>
                       You have successfully changed your 2FA settings.
                      </p>

                    </div>
                    <div class="form-group">
                     
                      <button class="btn btn-secondary" @click="closeModal()">
                        Close
                      </button>
                    </div>
                    
                    
                </div>
                </div>
             </div>
            <div v-if="state == 'init'">
                <div class="payment-container text-left d-block" >
                    <form class="form">
                         <div class="form-check px-5">
                        <input
                          class="form-check-input"
                          v-model="selectedOption"
                          type="radio"
                          value="pin"
                          @click="select('pin')"
                          
                        />
                        <label class="form-check-label" for="pin">
                          PIN
                        </label>
                      </div>
    
                       <div class="form-check px-5">
                        <input
                          class="form-check-input"
                          v-model="selectedOption"
                          type="radio"
                          value="emailAddress"
                          @click="select('emailAddress')"
                        />
                        <label class="form-check-label" for="emailAddress">
                          Email Address
                        </label>
                      </div>
    
                       <div class="form-check px-5">
                        <input
                          class="form-check-input"
                          v-model="selectedOption"
                          type="radio"
                          value="mobileNumber"
                          @click="select('mobileNumber')"
                        />
                        <label class="form-check-label" for="mobileNumber">
                         Mobile Number
                        </label>
                      </div>

                      <!-- <div class="form-check px-5">
                        <input
                          class="form-check-input"
                          v-model="selectedOption"
                          type="radio"
                          value="gAuth"
                          @click="select('gAuth')"
                        />
                        <label class="form-check-label" for="mobileNumber">
                        Google Authenticator
                        </label>
                      </div> -->
                    
  
                      
                    </form>
                    </div>
                    <hr>
                    <div v-if="selected == 'pin'"> 
                        <form  v-on:submit.prevent="usePin">
                    
                            <p class="text-center">Enter your PIN to use for authentication:</p>
                            <div class="alert alert-danger text-center" v-if="systemMsg != ''"> {{ systemMsg }} </div>
                            <div class="d-flex justify-content-center">
                                <div class="pin-wrapper" >
                                <div>
                                    <input id="pin1" type="text" autocomplete="off" maxlength="1" v-model="pinArr[0]" class="pin-input pin-input-100" @keydown="pinBack($event,1)" @keypress="isNumber($event)">
                                </div>
                        
                                <div>
                                    <input id="pin2"  type="text" autocomplete="off" maxlength="1" v-model="pinArr[1]" class="pin-input pin-input-100" @keydown="pinBack($event,2)" @keypress="isNumber($event)">
                                </div>
                        
                                <div>
                                    <input id="pin3" type="text" autocomplete="off" maxlength="1" v-model="pinArr[2]" class="pin-input pin-input-100" @keydown="pinBack($event,3)" @keypress="isNumber($event)">
                                </div>
                        
                                <div>
                                    <input id="pin4"  type="text" autocomplete="off" maxlength="1" v-model="pinArr[3]" class="pin-input pin-input-100" @keydown="pinBack($event,4)" @keypress="isNumber($event)">
                                </div>
                        
                                <div>
                                    <input id="pin5"  type="text" autocomplete="off" maxlength="1" v-model="pinArr[4]" class="pin-input pin-input-100" @keydown="pinBack($event,5)" @keypress="isNumber($event)">
                                </div>
                        
                                <div>
                                    <input id="pin6"  type="text" autocomplete="off" maxlength="1" v-model="pinArr[5]" class="pin-input pin-input-100" @keydown="pinBack($event,6)" @keypress="isNumber($event)">
                                </div>
                                </div>
                            </div>
                 
                    
                            <div class="form-group text-center">
                                <button type="submit" class="btn btn-main">SUBMIT</button>
                                
                            </div>
                        </form>
                    </div>

                    <div v-if="selected == 'email'">
                        <form v-on:submit.prevent="useEmail">
                            <p class="text-center">Enter your PIN to use Email authentication:</p>
                            <div class="alert alert-danger text-center" v-if="systemMsg != ''"> {{ systemMsg }} </div>
                            <div class="d-flex justify-content-center">
                                <div class="pin-wrapper" >
                                <div>
                                    <input id="pin1" type="text" autocomplete="off" maxlength="1" v-model="pinArr[0]" class="pin-input pin-input-100" @keydown="pinBack($event,1)" @keypress="isNumber($event)">
                                </div>
                        
                                <div>
                                    <input id="pin2"  type="text" autocomplete="off" maxlength="1" v-model="pinArr[1]" class="pin-input pin-input-100" @keydown="pinBack($event,2)" @keypress="isNumber($event)">
                                </div>
                        
                                <div>
                                    <input id="pin3" type="text" autocomplete="off" maxlength="1" v-model="pinArr[2]" class="pin-input pin-input-100" @keydown="pinBack($event,3)" @keypress="isNumber($event)">
                                </div>
                        
                                <div>
                                    <input id="pin4"  type="text" autocomplete="off" maxlength="1" v-model="pinArr[3]" class="pin-input pin-input-100" @keydown="pinBack($event,4)" @keypress="isNumber($event)">
                                </div>
                        
                                <div>
                                    <input id="pin5"  type="text" autocomplete="off" maxlength="1" v-model="pinArr[4]" class="pin-input pin-input-100" @keydown="pinBack($event,5)" @keypress="isNumber($event)">
                                </div>
                        
                                <div>
                                    <input id="pin6"  type="text" autocomplete="off" maxlength="1" v-model="pinArr[5]" class="pin-input pin-input-100" @keydown="pinBack($event,6)" @keypress="isNumber($event)">
                                </div>
                                </div>
                            </div>
                 
                    
                            <div class="form-group text-center">
                                <button type="submit" class="btn btn-main">SUBMIT</button>
                                
                            </div>

                        </form>
                    </div>

                    <div v-if="selected == 'sms'">
                        <form v-on:submit.prevent="useSms">
                            <p class="text-center">Enter your PIN to use SMS authentication:</p>
                            <div class="alert alert-danger text-center" v-if="systemMsg != ''"> {{ systemMsg }} </div>
                            <div class="d-flex justify-content-center">
                                <div class="pin-wrapper" >
                                <div>
                                    <input id="pin1" type="text" autocomplete="off" maxlength="1" v-model="pinArr[0]" class="pin-input pin-input-100" @keydown="pinBack($event,1)" @keypress="isNumber($event)">
                                </div>
                        
                                <div>
                                    <input id="pin2"  type="text" autocomplete="off" maxlength="1" v-model="pinArr[1]" class="pin-input pin-input-100" @keydown="pinBack($event,2)" @keypress="isNumber($event)">
                                </div>
                        
                                <div>
                                    <input id="pin3" type="text" autocomplete="off" maxlength="1" v-model="pinArr[2]" class="pin-input pin-input-100" @keydown="pinBack($event,3)" @keypress="isNumber($event)">
                                </div>
                        
                                <div>
                                    <input id="pin4"  type="text" autocomplete="off" maxlength="1" v-model="pinArr[3]" class="pin-input pin-input-100" @keydown="pinBack($event,4)" @keypress="isNumber($event)">
                                </div>
                        
                                <div>
                                    <input id="pin5"  type="text" autocomplete="off" maxlength="1" v-model="pinArr[4]" class="pin-input pin-input-100" @keydown="pinBack($event,5)" @keypress="isNumber($event)">
                                </div>
                        
                                <div>
                                    <input id="pin6"  type="text" autocomplete="off" maxlength="1" v-model="pinArr[5]" class="pin-input pin-input-100" @keydown="pinBack($event,6)" @keypress="isNumber($event)">
                                </div>
                                </div>
                            </div>
                 
                    
                            <div class="form-group text-center">
                                <button type="submit" class="btn btn-main">SUBMIT</button>
                                
                            </div>

                        </form>
                    </div>

                    <div v-if="selected == 'gAuth'"> 
                        <div class="qr-wrapper">
                            <qrcode-vue v-bind:value="qrData" v-bind:options="{ width: 200 }" class="qrCanvas"></qrcode-vue>
                        </div>
                        <form  v-on:submit.prevent="verifyPin" class="text-center">
                            
                            <p class="text-center">Enter your PIN:</p>
                            <div class="alert alert-danger text-center" v-if="showError"> {{ systemMsg }} </div>
                            <div class="d-flex justify-content-center">
                                <div class="pin-wrapper" >
                                    <div>
                                    <input id="pin1" type="password" autocomplete="off" maxlength="1" v-model="pinArr[0]" class="otp-input otp-input-100" @keydown="pinBack($event,1)" @keypress="isNumber($event)">
                                    </div>
                        
                                    <div>
                                    <input id="pin2"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[1]" class="otp-input otp-input-100" @keydown="pinBack($event,2)" @keypress="isNumber($event)">
                                    </div>
                        
                                    <div>
                                    <input id="pin3" type="password" autocomplete="off" maxlength="1" v-model="pinArr[2]" class="otp-input otp-input-100" @keydown="pinBack($event,3)" @keypress="isNumber($event)">
                                    </div>
                        
                                    <div>
                                    <input id="pin4"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[3]" class="otp-input otp-input-100" @keydown="pinBack($event,4)" @keypress="isNumber($event)">
                                    </div>
          
                                    <div>
                                        <input id="pin5" type="password" autocomplete="off" maxlength="1" v-model="pinArr[4]" class="otp-input otp-input-100" @keydown="pinBack($event,5)" @keypress="isNumber($event)">
                                    </div>
                            
                                    <div>
                                        <input id="pin6"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[5]" class="otp-input otp-input-100" @keydown="pinBack($event,6)" @keypress="isNumber($event)">
                                    </div>
                        
                                </div> 
                            </div>
                    
                            <div class="form-group ">
                                <button type="submit" class="btn btn-main" :disabled="isLoading">SUBMIT</button>
                               
                            </div>
          
                        </form>
                      
                    </div>
                    <div v-if="selected == 'otp'"> 
                        <form  v-on:submit.prevent="verifyOtp">
                    
                            <p class="text-center">Enter the one-time CODE you received:</p>
                            <div class="alert alert-danger text-center" v-if="systemMsg != ''"> {{ systemMsg }} </div>
                            <div class="d-flex justify-content-center">
                                <div class="otp-wrapper" >
                                <div>
                                    <input id="otp1" type="text" autocomplete="off" maxlength="1" v-model="otpArr[0]" class="otp-input otp-input-100" @keydown="pinBack($event,1)" @keypress="isNumber($event)">
                                </div>
                        
                                <div>
                                    <input id="otp2"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[1]" class="otp-input otp-input-100" @keydown="pinBack($event,2)" @keypress="isNumber($event)">
                                </div>
                        
                                <div>
                                    <input id="otp3" type="text" autocomplete="off" maxlength="1" v-model="otpArr[2]" class="otp-input otp-input-100" @keydown="pinBack($event,3)" @keypress="isNumber($event)">
                                </div>
                        
                                <div>
                                    <input id="otp4"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[3]" class="otp-input otp-input-100" @keydown="pinBack($event,4)" @keypress="isNumber($event)">
                                </div>
                        
                                <div>
                                    <input id="otp5"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[4]" class="otp-input otp-input-100" @keydown="pinBack($event,5)" @keypress="isNumber($event)">
                                </div>
                        
                                <div>
                                    <input id="otp6"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[5]" class="otp-input otp-input-100" @keydown="pinBack($event,6)" @keypress="isNumber($event)">
                                </div>
                                </div>
                            </div>
                          <div class="text-center">
                          <p @click="resendCode" v-if="!ifResent" style="cursor:pointer;">Didn't receive 2FA?  <span style="text-decoration: underline;">Try Again</span></p>
                          <p v-if="ifResent">{{ timeFormat(timerInSeconds) }}</p>
                        </div>
                         <div class="form-group ">
                                <button type="submit" class="btn btn-main" :disabled="isLoading">SUBMIT</button>
                               
                            </div>
                      </form>
                      </div>
                      <div v-if="showQr">
                        <qrcode-vue :value="qrData" :options="qrOptions" />
                      </div>
                  <button @click="closeModal" class="btn btn-secondary" >  
                    <span>Cancel</span>	
                  </button>
            </div>
          </div>

        </div>
      </div>
     </div>
  </template>

  <script>
    import QrcodeVue from "qrcode.vue";

     export default {
          name: 'ChangeAuthComponent',
          components:{
            QrcodeVue
          },
          data(){
              return {
                qrData:'',
                qrOptions: {
                    size: 1000, // QR code size in pixels
                    bgColor: "#FFFFFF", // Background color
                    fgColor: "#000000" // Foreground color
                    // More options can be added here
                },
                showQr: false,
                state:'init',
                selected: '',
                selectedOption: null,
                authzCode: '',
                loginPass:'',
                googleAuth:'',
                ifResent: true,
                timerInSeconds: 60,
                pinArr: [],
                otpArr: [],
                systemMsg: "",
                showError: false,
					      
					      	errors: {
					      		amount: "",
					      		secretKey: "",
					      		file: "",
					      		importSecretKey: ""
					      	},
							systemMsg: "",
							
					      
              }
          },
          methods:{
            closeModal(){
              this.$emit("close");
              window.location.reload();
          },
          select(selectedOption){
            this.selectedOption = selectedOption
            if(this.selectedOption == 'pin'){
                this.selected = 'pin'
                this.clearError()
                
            }
            if(this.selectedOption == 'emailAddress'){
                this.selected = 'email'
                this.clearError()
                // this.timer();
            }
            if(this.selectedOption == 'mobileNumber'){
                this.selected = 'sms'
                this.clearError()
                // this.timer();
            }
            if(this.selectedOption == 'gAuth'){
                 this.selected = 'gAuth'
                this.clearError()
              
                this.timer();
            }
          },

          usePin(){
            const params = {
                loginPass: this.authzCode,
                authzCode: this.pinArr[0] + this.pinArr[1] + this.pinArr[2] + this.pinArr[3] + this.pinArr[4] + this.pinArr[5],
                twoFa:'PIN'
                }
                    const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                    params,
                    url: '/cx/profile/update2fa',
                    };
                    this.axios(options)
                        .then((response) => {
                            this.state = 'successful'
                        })
                        .catch((error) => {
                            // Error code goes here
                            this.showError = true
                            this.systemMsg = error.response.data.msgText
                           
                            setTimeout(()=>{
                            this.clearError()
                        },3000);
                    });
            },
            useEmail(){
                const params = {
                    loginPass: this.authzCode,
                authzCode: this.pinArr[0] + this.pinArr[1] + this.pinArr[2] + this.pinArr[3] + this.pinArr[4] + this.pinArr[5],
                twoFa:'EMAIL'
                }
                    const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                    params,
                    url: '/cx/profile/update2fa',
                    };
                    this.axios(options)
                        .then((response) => {
                            this.state = 'successful'
                        })
                        .catch((error) => {
                            // Error code goes here
                            this.showError = true
                            this.systemMsg = error.response.data.msgText
                           
                            setTimeout(()=>{
                            this.clearError()
                            },3000);
                    });

            },
            useSms(){
                const params = {
                    loginPass: this.authzCode,
                authzCode: this.pinArr[0] + this.pinArr[1] + this.pinArr[2] + this.pinArr[3] + this.pinArr[4] + this.pinArr[5],
                twoFa:'SMS'
                }
                    const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                    params,
                    url: '/cx/profile/update2fa',
                    };
                    this.axios(options)
                        .then((response) => {
                            this.state = 'successful'
                        })
                        .catch((error) => {
                            // Error code goes here
                            this.showError = true
                            this.systemMsg = error.response.data.msgText
                           
                            setTimeout(()=>{
                            this.clearError()
                        },3000);
                    });

            },
            getGAuth(){
                    const options = {
                    method: 'GET',
                    headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                  
                    url: '/cx/generateqrcode/gauthkey',
                    };
                    this.axios(options)
                        .then((response) => {
                            console.log(response.data)
                            this.qrData = response.data
                        })
                        .catch((error) => {
                           
                    });

            },

            useGoogleAuth(){
                const params = {
                loginPass: this.authzCode,
                authzCode: this.pinArr[0] + this.pinArr[1] + this.pinArr[2] + this.pinArr[3] + this.pinArr[4] + this.pinArr[5],
                twoFa:'GAUTH'
                }
                    const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                    params,
                    url: '/cx/profile/update2fa',
                    };
                    this.axios(options)
                        .then((response) => {
              
                        })
                        .catch((error) => {
                            // Error code goes here
                            this.showError = true
                            this.systemMsg = error.response.data.msgText
                           
                            setTimeout(()=>{
                            this.clearError()
                        },3000);
                    });

            },

            close: function(){
                this.$bvModal.hide('change-pin-modal'); 
             
                this.systemMsg = ""
                this.showError = false
             
            },
            
            formatAmount: function(num){

                return numeral(num).format('0,0.00000000');
            },

            resendCode: function() {
                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                     url: '/cx/private/resend2fa',
                };
 
                    this.axios(options)
                    .then((response ) => { 
                        this.systemMsg = ""
                        this.showError = false
                        this.ifResent = true
                        console.log(this.ifResent)
                        this.timer();
                    }).catch((err) => {
                        console.log('resend code errror')
                                 console.log(err)
                        })
            },
 
            timer() {
                    let counterInterval = setInterval(() => {
                    this.timerInSeconds--;
                    // console.log(this.timerInSeconds)
 
                    if(this.timerInSeconds == 0 || this.ifResent == false) {
                        clearInterval(counterInterval)
                        this.ifResent = false
                        this.timerInSeconds = 60
                     }
                        }, 1000);
            },
 
            timeFormat(seconds) {
                let date = new Date(0);
                date.setSeconds(seconds);
                return date.toISOString().substr(14, 5);
            },
 
            verifyPin() {
                 const params = {
                    pin: this.pinArr[0] + this.pinArr[1] + this.pinArr[2] + this.pinArr[3] + this.pinArr[4] + this.pinArr[5],
                }
 
                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                 .join('&');
                         
 
                 const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                    data,
                    url: '/cx/private/validatepin',
                };
                    this.axios(options)
                    .then((response) => {
                        this.selected = 'otp'
                        this.clearError();
                        this.timer();
                    })
                    .catch((error) => {
                        // Error code goes here
                        this.pinArr = []
                         this.showError = true
                        this.systemMsg = error.response.data.msgText
                        console.log(error.response.data.msgText)
                        setTimeout(()=>{
                        this.clearError()
                        },3000);
                        });
 
            },
 
            clearError() {
                this.systemMsg = ""
                this.showError = false
                this.otpArr = [];
                this.pinArr = [];
             },
            getMobileCodes(){
                this.axios.get('/assets/js/mobilecodes.json')
                    .then((response) => {
                        this.list.mobileCodes = response.data;
                    }).catch((err) => {
                        console.log(err);

                    })
            },
 
            verifyOtp() {
                const params = {
                    otp: this.otpArr[0] + this.otpArr[1] + this.otpArr[2] + this.otpArr[3] + this.otpArr[4] + this.otpArr[5],
                }
 
                const data = Object.keys(params)
                    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                    .join('&');
                         
 
                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                    data,
                    url: '/cx/private/validate2fa',
                    };
                    this.axios(options)
                        .then((response) => {
                      
                        this.clearError();
                     })
                    .catch((error) => {
                         // Error code goes here
                        this.showError = true
                        this.otpArr = []
                        this.systemMsg = error.response.data.msgText
                            console.log(error)
                            setTimeout(()=>{
                            this.clearError()
                        },3000);
                        });
                 },
 
               isNumber: function(evt) {
                            
                    evt = (evt) ? evt : window.event;
                    var charCode = (evt.which) ? evt.which : evt.keyCode;
                        if ((charCode > 31  && (charCode < 48 || charCode > 57)) ) {
                             evt.preventDefault();
                             } else {
                         return true;
                             }
                },
                isAmount: function(evt) {
                    evt = (evt) ? evt : window.event;
                    var charCode = (evt.which) ? evt.which : evt.keyCode;
  
                        if ((charCode > 31 && charCode != 46  && (charCode < 48 || charCode > 57)) ) {
                        evt.preventDefault();
                        } else {
                         return true;
                        }
                },
                pinBack: function(event, currrentBox) {
                    if(this.selected == 'pin' || this.selected == 'gAuth' || this.selected == 'email' || this.selected == 'sms') {
                        if (event.keyCode === 8) {
                             if (currrentBox != 1) {
 
                                 setTimeout(function() { 
                                     document.getElementById("pin"+ (currrentBox - 1)).focus();
                                 }, 50)
 
                             }
                         }
 
                         if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {
 
                             setTimeout(function() { 
 
                                 if((currrentBox) < 6) {
                                     document.getElementById("pin"+ (currrentBox + 1)).focus();
                                 }
                                 
                             }, 50)
 
                         }
                         } else if(this.selected == 'otp') {
                             if (event.keyCode === 8) {
                             if (currrentBox != 1) {
 
                                 setTimeout(function() { 
                                     document.getElementById("otp"+ (currrentBox - 1)).focus();
                                 }, 50)
 
                             }
                         }
 
                         if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {
 
                             setTimeout(function() { 
 
                                 if((currrentBox) < 6) {
                                     document.getElementById("otp"+ (currrentBox + 1)).focus();
                                 }
                                 
                             }, 50)
 
                         }
                         }
                },

        
            
    
      },
      mounted(){
        this.getGAuth();
      }
    }

  </script>


  <style scoped lang="scss">

  	


  .b-modal{
    width: 400px;
    height: auto;
    margin: 100px auto;
    background: white;
    border-radius: 10px;
    z-index: 2;
    padding-bottom: 50px;
    max-height: calc(100vh - 110px);
    overflow-y: auto;
  }
.modal-header{
    width: 100%;
    background: rgb(2,11,54);
      background: linear-gradient(180deg, rgba(2,11,54,1) 3%, rgba(1,16,61,1) 35%, rgba(0,31,80,1) 80%);
  }
.modal-title{
        margin: 0 auto;
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
    
  }
  label{
    color: black;
  }

.modal-title  svg {
    	fill: #FFF;
    	cursor: pointer;
    }
.modal-title img{
    width: 4rem;
  }
  
.backdrop{
    top: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    z-index: 1;
  }
.b-modal h4{
    padding: 15px;
  }
  .btn{
    margin-right: 10px;
  }
   @media only screen and (max-device-width: 580px) {
       /* styles for mobile browsers smaller than 480px; (iPhone) */
       .b-modal{
        width: 300px;
        height: auto;
        margin: 175px auto;
        background: white;
        border-radius: 20px;
        border-color:white;
        border-width:2px;
        z-index: 99;
        overflow-x: hidden !important;
        color:black;
      }
       
    }
    .otp-wrapper, .pin-wrapper {
       display: flex;
       width: 80%;
       justify-content: center;
       margin-bottom: 1em;
       div {
       margin: 0.25em;

       .pin-input, .otp-input {
               width: 47px;
               text-align: center;
               border-radius: 6px;
               outline: none;
               box-shadow: none;
               font-size: 2em; 
               border: 2px solid black;
               background: #FFFFFF;
               color: #262d33;
               border-radius: 6px;
               height: 60px;
       }
     }
     }
 
  </style>