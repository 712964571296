import { createStore } from 'vuex'

export default createStore({
  state: {
    init:{
      balance: {},
      currency:'PHP',
      amount: '',
      txnControlId:'',
      history: {},
      fundHistory: [],
      currentLocation: ''
    },
    show: {
      hasSession: false,
      showChangePin: false,
      showUnverify: false,
      login: false,
      signup: false,
      logout: false,
      guidelines: false,
      profile: {},
      corpDetails:{
        corpInfo:{},
        corpOfficeAddress:{},
        tradingAddress:{},
      },
      showDeposit: false,
      showWithdraw: false,
      showBuyLoad: false,
      showSendFunds: false,
      showPaybills: false,
      showPrepaidCard: false,
      showRequestFunds: false,
      showCryptoDeposit: false,
      showCryptoWithdraw: false,
      showRequestFunds: false
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
