<template>
	<!--july 27-->
	<div>
	  <nav class="navbar navbar-light bg-light">
		<div class="container-fluid">
		  <a class="nav-title" href="javascript:;"><img :src="require('../assets/logo.png')" @click="goToHome">   
			<!-- <span class="store-item-icon">
			<b-icon icon="cart" scale="1" shift-h="5" shift-v="5"></b-icon>
		  </span> -->
		</a>
		 
			<div class="d-flex" v-if="!show.hasSession">
			  <div class="control">
				<a class="button">
				  <span  @click="goToShop1" >SHOP</span>
				</a>
			  </div>
			  <div class="control">
				<a class="button">
				  <span @click="goToTrade1" >TRADE</span>
				</a>
			  </div>
			  <div class="control">
				<a class="button">
				  <span class="active">WALLET</span>
				</a>
			  </div>
			</div>
		  <div class="d-flex" v-if="!show.hasSession">
			<div class="control">
			  <a class="button">
				<span @click="redirect('login')"  class="btn btn-login" >{{ loginLabel }}</span>
			  </a>
			</div>
			<div class="control">
			  <a class="button">
				<span @click="redirect('signup')"  class="btn btn-signup">{{ signupLabel }}</span>
			  </a>
			</div>
		  </div>
		  
  
		  
  
		  <div class="btn-group-horizontal web-view" v-if="show.hasSession">
				  <div class="header-wrapper">
					<div class="profile-wrapper">
					  <img src="../assets/images/settings.png" class="settings" v-on:click="toggleSidebar">
					</div>
					<div class="profile-wrapper">
					
					  <div>
						<img src="../assets/images/account.png">
					  </div>
		  
					  <div class="name-wrapper" >
						<div class="text-white text-center"> {{ show.profile.fullName }} </div>
						<div>
						  <button v-if="show.corpDetails.corpInfo == undefined" class="btn"  @click="openProfile"  :class="[ show.profile.verificationStatus == 'VERIFIED' ? 'verified' : 'unverified' ]" > INDIVIDUAL - {{ show.profile.verificationStatus }} </button>
						  <button v-if="show.corpDetails.corpInfo != undefined" class="btn" @click="openProfile" :class="[ show.profile.verificationStatus == 'VERIFIED' ? 'verified' : 'unverified' ]" > BUSINESS -  {{ show.profile.verificationStatus }} </button>
						</div>
		  
					  </div>
					
					</div>
		
				  </div>
		  </div>
		</div>
		<div class="container-fluid">
		  <div class="div">
  
		  </div>
		 
			<div class="d-flex" v-if="show.hasSession">
			  <div class="control">
				<a class="button">
				</a>
			  </div>
			  <div class="control">
				<a class="button">
				</a>
			  </div>
			  <div class="control">
				<a class="button">
				</a>
			  </div>
			</div>
		
		  <div class="web-view" v-if="show.hasSession">
				  <div class="header-wrapper-services">
					<div class="control">
					  <a class="button">
						<span  @click="goToStore" >SHOP</span>
					  </a>
					</div>
					<div class="control">
					  <a class="button">
						<span @click="goToTrade" >TRADE</span>
					  </a>
					</div>
					<div class="control">
					  <a class="button">
						<span class="active">WALLET</span>
					  </a>
					</div>
					<!-- <div class="control">
						<a class="button">
						  <span @click="overview" >My PORTFOLIO</span>
						</a>
					  </div> -->
		
				  </div>
		  </div>
		</div>
	  </nav>
		<Logout @close="openLogout" v-if="showLogoutModal"/>
		<ChangePin @close="openChangePin" v-if="showChangePin"/>
		<TheNavigation @close="toggleSidebar" v-if="showSidebar && show.hasSession" />
	</div>
  </template>
  

<script>
	import ChangePin from "../components/modal/ChangePin.vue";
	import Logout from "../components/modal/Logout";
	import TheNavigation from "../components/TheNavigation.vue"
	export default {
		name: 'Header',
		components:{
			ChangePin,
			Logout,
			TheNavigation
		},
		data(){
			return{
				isUserLoggedIn: "account login",
				isUserSignedUp: "create an account",
				loginLabel: "Log in",
				signupLabel: "Sign up",
				showLoginModal: false,
				showSignupModal: false,
				showChangePin: false,
				showLogoutModal: false,
				pathname:'',
				showSidebar: true
				
			}

			
		},
		computed: {
		    show() {
		        return this.$store.state.show;
		    },
			init() {
		        return this.$store.state.init;
		    },
	    },
	    methods: {
			toggleSidebar: function(){
			this.showSidebar = !this.showSidebar
			},
			openChangePin(){
				this.show.showChangePin = !this.show.showChangePin
			},
			goToStore(){
				window.location.href="https://store.coexstar.com/#/authz/" + localStorage.getItem("authz");
			},
			overview(){
				window.location.href="https://overview.coexstar.com/#/authz/" + localStorage.getItem("authz");
			},
			goToWallet(){
				window.location.href="https://wallet.coexstar.com/login.html?authorization=" + localStorage.getItem("authz");
			},
			goToTrade(){
				window.location.href="https://trade.coexstar.com/#/authz/" + localStorage.getItem("authz");
			},
			goToWallet1(){
			window.location.href="https://wallet.coexstar.com/login.html";
			},
			goToTrade1(){
			window.location.href="https://trade.coexstar.com/#/";
			},
		
			goToShop1(){
			window.location.href="https://store.coexstar.com/#/";
			},
			goToDefault(){
				window.location.href="/#/"
			},
			// signupModal(){
			// 	window.location.href="/#/signup"
			// },
			// loginModal(){
			// 	window.location.href="/#/login"
			// },
			redirect(route) {
				//this.$router.push({name: route, params: {countryCode: localStorage.getItem('country')}})

				window.location.href = "/#/" + this.init.currentLocation.toLowerCase() + "/" + route;
			},
			openProfile(){
				window.location.href = "/#/" + this.init.currentLocation.toLowerCase() + "/" + 'kyc/';
			},
			goToVerification(){
				window.location.href = "/#/" + this.init.currentLocation.toLowerCase() + "/" + 'verification/';
			},
	    	logout() {
		        this.axios.post(this.$r.logout)
		        	.then((response) => {
		            	window.location.href = "/#/" + this.init.currentLocation.toLowerCase() + "/" + 'login/';
		         	}).catch((err) => {
		            	console.log(err)
		            	window.location.href = "/#/" + this.init.currentLocation.toLowerCase() + "/" + 'login/';
		        	})
		    },
	    },
	    mounted() { 
			
	    }
	}

</script>

<style scoped lang="scss">
 
	.navbar {
		display: flex;
		justify-content: space-between !important;
		background-color: transparent !important;
		width: 100%;
		height: auto;
}
.nav-title {
  padding: 40px;
  color: white;
  text-decoration: none;
  font-family: 'SoinSansNeue';
}
.nav-title  img{
  width: 10rem;
}

.btn-login{
  background: #695536 !important;
    width: 100%;
    color: #FFF !important;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 0.9em;
    letter-spacing: 0.62px;
    margin: 0.5em;
    font-family: 'SoinSansNeue';
    border: none;
    border-radius: 14px;

}
.btn-signup{
    background: transparent !important;
    width: 100%;
    color: #FFF !important;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 0.9em;
    letter-spacing: 0.62px;
    margin: 0.5em;
    font-family: 'SoinSansNeue';
    border: #695536 1px solid;
    border-radius: 14px;
}
.btn-signup:hover{
    background: #CFA76B !important;
   
}
.btn-login:hover{
    background: #CFA76B !important;
   
}
	.unverified {
		background: #695536;
		color: white !important;
	}
	.verified {
		background: #024301;
    	border: 1px solid linear-gradient(to top, #aa6c00 0%, #f9cb10 100%);
    	font-family: 'SoinSansNeue-Bold';
      color: white !important;
	}
	.logo {
		width: 15em;
	    margin-top: -14px;
	    margin-left: 1em;
	}
	.header{
		display: flex;
		justify-content: space-between !important;
	}

	.header nav {
		display: flex;
		justify-content: space-between !important;
		background: transparent;
	    display: flex;
	    align-items: center;
	    justify-content: end;
		margin: 20px;

	    a {
	    	color: #FFF;
	    }
	}

	.header .hamburger { 
		padding: 7px;
		border-radius: 3px;
	}
	.btn-icon{
		background-color: transparent !important;
		font-size: 0.5rem;
	}
	.btn-icon img{
		width: 2rem !important;
	}

	.header .hamburger.open {
	    /*padding-right: 0;*/
	}
	.header .hamburger.open > span:first-child {
		transform: rotate(45deg);
	}

	.header .hamburger.open > span:nth-child(2) {
		opacity: 0;
	}

	.header .hamburger.open > span:last-child {
		transform: rotate(-45deg);	
	}

	.header .hamburger > span {
		display: block;
	    width: 26px;
	    height: 3px;
	    background: #FFFFFF;
	    transform-origin: 1.75px 1.75px;
	    transition: all 300ms ease-in-out;
	}

	.header .hamburger > span:not(:last-child) {
		margin-bottom: 5px;

	}
 
	.header-wrapper {
		display: flex !important;
    	align-items: center;
		justify-content: end !important;
		float: right !important;
	}
	.header-wrapper-services {
		display: flex !important;
    	align-items: center;
		justify-content: end !important;
		float: right !important;
	}
  .profile-wrapper img{
    margin-right: 1em;
			width: 50px;
  }
  .profile-wrapper .btn{
    padding: 0.5em;
			font-size: 17px;
			margin-top: 0.5em;
  }

	.profile-wrapper {
		display: flex;
		align-items: center;
  }
  .profile-wrapper .btn-second{
    background: #aa6c00;
				color: #FFF;
				cursor: pointer;
  }
  .profile-wrapper .btn-second:hover{
    background: linear-gradient(to top, #f9cb10 0%, #aa6c00 100%);
  }
  .profile-wrapper .btn-second img{
    width: 2rem;
  }
	
		


	@media only screen and (max-width: 675px) {
    .logo {
		width: 10em;
	    margin-top: -14px;
	    margin-left: 1em;
	}
	.header nav {
		
	    justify-content: space-between;
	}
   }
   .control a {
  padding: 10px;
  background: transparent;
  border: solid transparent;
  color: white !important;
  font-weight: lighter;
  cursor: pointer;
  text-decoration: none;
  font-family: 'SoinSansNeue';
  margin: 10px;
}
.control:focus {
  outline: none;
}
.control a .active {
  /* color: #695536 !important; */
  font-weight: bolder !important;
  font-family: 'SoinSansNeue-Bold';
  padding: 10px;
}
.control a:hover {
  font-weight: bolder !important;
  font-family: 'SoinSansNeue-Bold';
}
#forgotPassword {
  float: right;
  margin: 0 20px;
}
.icon{
  width: 1rem;
}

@media screen and (min-width: 280px) and (max-width: 568px) {
  .web{
  display: none;
}
}
@media only screen and (min-width: 275px) and (max-width: 768px) {
    
    .btn-group-horizontal{
      display: block !important;
      justify-content: center;
      margin: 0 auto;
    }
    .btn-group-horizontal .dropdown{
      width: 100%;
    }
	.container-fluid{
      display: flex !important;
      flex-direction: column !important;
      justify-content: center;
      margin: 0 auto;
    }
	.header-wrapper-services{
      display: flex;
      flex-direction: column;
      justify-content: center !important;
    }
  }
  .btn-second {
    background: transparent;
    width: 100%;
    color: #FFF;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 0.9em;
    letter-spacing: 0.62px;
    margin: 0.1em;
    border: none;
    font-family: 'SoinSansNeue';
    border-radius: 3px !important;
  }
  

</style>
