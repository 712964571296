<template>
  <div class="backdrop">
    <div class="b-modal">
        <div class="modal-header text-right">
            <h5 class="mt-2 text-white">REDEEM</h5>
            <p @click="closeModal" class="x">x</p>
        </div>
      <div class="modalBody">
        <form @submit.prevent="redeemCode">
          <div style="color: red;" v-if="showError"> {{ systemMsg }} </div>
          <div class="form-container">
            <label for="amount">Enter Gift Code:</label>
            <div class="input-group">
              <input type="text" name="amount"  v-model="giftCode" class="form-control" id="current">
             
              </div>
          </div>
          <div class="flex  margin-top mt-3">
            <button  class="btn btn-main" type="submit">  
              <span>Redeem Gift Code</span>	
            </button>
          
             
          </div>
          </form>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  
    components: {

    },
    data: function() {
        return {
          giftCode:'',
          systemMsg:'',
          showError: false,
          
            
        }
    },

    computed: {
     
    },

    mounted() {
     
    },

    watch: {
      
    },

    methods: {
       

        closeModal(){
            this.$emit("close")
         
        },

        done(){
          this.$emit("close")
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
             var charCode = (evt.which) ? evt.which : evt.keyCode;
              if ((charCode > 31  && (charCode < 48 || charCode > 57)) ) {
                evt.preventDefault();
                } else {
                return true;
              }
        },
        redeemCode(){
          const params = {
            promoCode: this.giftCode
          }
          const options ={
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId")  },
            params,
            url:'/cx/redeempromocode'
          }

          this.axios(options)
              .then((response) => {
                 console.log(response.data)
                 
              }).catch((err) => {
                  console.log(err);
                  this.showError = true;
                  this.systemMsg = err.response.data.msgText
                      console.log(err)
                          setTimeout(()=>{
                            this.showError = false,
                            this.systemMsg = ""
                          },3000);
              });
        },
      
    }
}
</script>

<style scoped>
.backdrop {
    top: 0;
    right:0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    background: rgba(0, 0, 0, 0.6);

}
.b-modal {
    width: 620px;
    height: auto;
    margin: 50px auto;
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 99;
    overflow-x: hidden !important;
    color:black;
    max-height: calc(100vh - 110px);
    overflow-y: auto;
}

.modal-header {
  background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    margin-right:0px;
    font-size:25px;
    display:flex;
    justify-content:space-between;
}

.modal-title{
    margin: 0 auto;
    color:white;
}

.modalBody {
  background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    width:100%;
    padding:50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
}

.buttonWrapper {
    width:100%;
    margin-top: 10px;
    padding-top: 15px;
}

.x {
    cursor:pointer;
}
.red{
    color: red;
}




    @media screen and (max-width: 1100px) {
    
          
    }
   @media screen and (max-width: 800px) {
     
  }

    @media screen and (max-width: 600px) {
       
        
    }

    @media screen and (min-width: 280px) and (max-width: 420px) {

       .b-modal {
            width: 350px;
            height: auto;
            margin-top: 50px;
            background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
          background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
            width:100%;
            padding: 20px 10px;
            border-radius: 10px;
        }
        

  
      


    }

    @media screen and (min-width: 480px) and (max-width: 580px) {
        .b-modal {
                width: 405px;
        }

    
     
    }
    .wrapper{
      display: flex;
       padding: 2em;
      justify-content: center !important;
      margin: 0 auto !important;
  }
 
    .product-grid {
      display: flex;
     
    }

  .product-card {
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
      box-shadow:0 0 30px rgba(0,0,0,0.3);
      border: 1px solid transparent;
      border-radius: 12px;
      width: 7rem;
      height: auto;
      text-align: center;
      margin: 10px !important;
      color: white;
      cursor: none;
  
  }


  .product-image {
    padding: 10px 0px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      margin: 0 auto;
      background-color: none !important;
    width: 100%;
    max-width: 80px;
    height: auto;
    margin-bottom: 10px;
  }
</style>